<template>
    <div>
        <el-card>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>文章管理</el-breadcrumb-item>
                <el-breadcrumb-item>最新政策</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="hr-20"></div>
            <div>
                <el-form label-width="0" class="ruleForm" size="small" inline @submit.native.prevent>
                    <el-form-item class="fl">
                        <el-button type="primary" plain @click="addCreateView">添加</el-button>
                    </el-form-item>

                  <el-form-item class="fl">
                    <el-button type="primary" plain @click="requestRefresh" icon="el-icon-refresh">刷新</el-button>
                  </el-form-item>

                    <el-form-item class="fr">
                        <el-button type="primary" @click="requestNotice" style="color: #f0f0f0">查询</el-button>
                    </el-form-item>

                    <el-form-item class="fr">
                        <el-input v-model="searchKeyword" clearable placeholder="请输入关键字" @keyup.enter.native="requestNotice"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <el-table :data="tableData" ref="multipleTable">
                <el-table-column prop="title" label="政策标题" show-overflow-tooltip align="left" min-width="320px"/>
                <el-table-column label="创建时间" width="120">
                    <template slot-scope="scope">{{ scope.row.createTime | formatDate}}</template>
                </el-table-column>

                <el-table-column label="修改时间" width="120" >
                    <template slot-scope="scope">{{ scope.row.modifiedTime | formatDate}}</template>
                </el-table-column>
                <el-table-column label="状态" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="success" size="mini" v-if="scope.row.status===1">已发布</el-tag>
                        <el-tag type="warning" size="mini" v-else>编辑中</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="操作" align="center" width="300" fixed="right">
                    <!-- eslint-disable-next-line -->
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning" plain v-if="scope.row.status===1"
                                   @click="modifystatus(scope.row.id,0)">撤回
                        </el-button>
                        <el-button size="mini" type="success" plain v-else @click="modifystatus(scope.row.id,1)">发布
                        </el-button>
                        <el-button size="mini" @click="requestEdit(scope.row)">编辑</el-button>
                        <el-button plain type="danger" size="mini" @click="deleteData(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination background layout="prev, pager, next" :total="pageSize" :page-size="size" :current-page="page"
                           @current-change="requestPages" class="pagination" v-if="searchKeyword.length>0"/>
            <el-pagination background layout="prev, pager, next" :total="pageSize" :page-size="size" :current-page="page"
                           @current-change="requestPage" class="pagination" v-else/>
            <span style="float: left;margin: -40px 0 0 0;">共计{{pageSize}}条数据</span>
        </el-card>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                editing: {},
                page:this.$route.query.page || 1,
                pages: 1,
                size: 10,
                pageSize: 0,
                createProgress: false,
                form: {
                    columnId: 0,
                    title: '',
                    type: '',
                    status: 0,
                    content: '',
                },
                editForm: {
                    id: '',
                    title: '',
                    content: ''
                },
                tableData: [],
                selectionDate: [],
                searchKeyword: "",
            }
        },
        mounted() {
            this.getNotice()
        },
        methods: {
          request() {
            let newPage = this.$router.resolve({
              name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
          },
            //初始化数据列表
            getNotice() {
                let self = this;
                this.$axios.get('/column/page').then(function (response) {
                    if (response.data.status === 200) {
                        response.data.data.records.forEach(function (Array) {
                            if (Array.name === '最新政策') {
                                self.form.columnId = Array.id
                                self.$axios.get('/article/column', {
                                    params: {
                                        columnId: Array.id,
                                        current: self.$route.query.page|| 1,
                                        size: self.size
                                    }
                                }).then(function (response) {
                                    self.tableData = response.data.data.records;
                                    self.pageSize = response.data.data.total;
                                }).catch(error => {
                                    console.error(error)
                                })
                            }
                        })
                    } else {
                        self.$root.warn("数据加载失败");
                        console.log(response.data.status)
                    }
                }).catch(error => {
                    console.error(error)
                })
            },

          //  刷新
          requestRefresh() {
              this.pageSize = 0;
              this.getNotice();
              this.searchKeyword = '';

          },

            //  点击添加
            addCreateView() {
                this.$router.push({name: 'add-policy'})
            },


            /**
             * 编辑
             * @param row object
             */
            requestEdit(row) {
              let self = this;
              if (row.status!==0){
                self .$root.warn('已发布的内容必须撤回才能编辑！');
              }else {
                self.$router.push({name: 'edit-policy',query: {id: row.id,page:this.page}})
              }
            },

            /**
             * 发布撤回
             * @param id
             * @param status
             */
            modifystatus(id, status) {
                let self = this;
                let Status = {
                    id: id,
                    status: status
                }
                this.$axios.put('/article', Status).then(function (res) {
                    self.createLoading = false;
                    if (res.data.status === 200) {
                        self.editDialog = false
                        status === 0 ? self.$root.success("撤回成功") : self.$root.success("发布成功")
                        self.requestPage(self.page)
                    } else {
                        status === 0 ? self.$root.success("撤回失败") : self.$root.success("发布失败")
                    }
                }).catch(error => {
                    self.createLoading = false;
                    console.error(error)
                })

            },

            /**
             * 删除
             * @param row object
             */
            deleteData(row) {
                this.$confirm('确定删除该政策, 是否继续?', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios.delete('/article?id=' + row.id).then(response => {
                        if (response.data.status === 200) {
                            this.$root.success("删除成功")
                            this.getNotice()
                        } else {
                            this.$root.warn("删除失败")
                        }
                    }).catch(error => {
                        console.error(error)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },

            //  查询
            requestNotice() {
                this.pageSize = 1
                this.$axios.post('/article/condition', {
                    columnId: this.form.columnId,
                    title: this.searchKeyword,
                    current: 1,
                    size: 10,
                }).then(response => {
                    this.tableData = response.data.data.records;
                    this.pageSize = response.data.data.total
                    this.pages = 1
                    if (this.searchKeyword.length === 0) {
                        this.getNotice();
                        this.pageSize =1
                    }
                }).catch(error => {
                    console.error(error)
                })
            },

            /**
             *查询时的分页
             * @param value Number
             */
            requestPages(value) {
              this.page = value
                let self = this
                self.$axios.post('/article/condition', {
                    columnId: self.form.columnId,
                    title: this.searchKeyword,
                    current:value,
                    size:self.size
                }).then(response => {
                    self.tableData = response.data.data.records
                    self.pageSize = response.data.data.total
                }).catch(error => {
                    console.error(error)
                })
            },

            /**
             * 分页
             * @param value Number
             */
            requestPage(value) {
                this.page = value
                let self = this;
                self.$axios.get('/article/column',{
                    params:{
                        columnId: self.form.columnId,
                        current:value,
                        size:self.size
                    }
                }).then(function (response) {
                    self.tableData = response.data.data.records;
                }).catch(error => {
                    console.error(error)
                })
            },


        }
    }
</script>

<style scoped lang="less">
    #form {
        padding: 40px;
        color: #1091ed;
    }

    .pagination {
        margin: 32px 0 16px 0;
    }
</style>